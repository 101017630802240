<template>
  <div class="Invitation">
    <div class="img_T" style="margin: 20px auto">
      <img src="../../assets/zxx_img/yy.png" alt="" />
    </div>
    <div class="inform-main">
      <div class="I_item">
        <el-input
          v-model="page.goodsNameOrNum"
          placeholder="请输入商品名称/商品编号"
          style="width: 400px"
        ></el-input>
        <el-input
          v-model="page.enterpriseName"
          placeholder="请输入企业名称"
          style="width: 400px; margin: 0 20px"
        ></el-input>
        <el-select
          v-model="page.orderType"
          placeholder="请选择交易类型"
          @change="optionsChange"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="page.confirmStatus"
          placeholder="请选择发起状态"
          style="margin: 0 20px"
        >
          <el-option
            v-for="item in option"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-button type="primary" @click="searchFor">搜索</el-button>
        <el-button type="primary" @click="resetting">重置</el-button>
      </div>
      <div style="display: flex; justify-content: flex-end; margin-top: 10px">
        <el-button type="primary" @click="addinvitation"
          >发起邀约交易</el-button
        >
      </div>
      <div style="background-color: #fff; margin-top: 10px">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="我发起的" name="first">
            <initiate :list="list" @change="receivedChange"></initiate>
          </el-tab-pane>
          <el-tab-pane label="我收到的" name="second">
            <received :list="list" @change="receivedChange"></received>
          </el-tab-pane>
          <el-tab-pane label="其他邀约" name="third">
            <other :list="list" @change="receivedChange"></other>
          </el-tab-pane>
        </el-tabs>
        <div style="display: flex; justify-content: right">
          <el-pagination
            layout="prev, pager, next"
            :total="total"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { listTradeInvite } from "@/api/foundation";
import { getCompanyAuthDetail } from "@/api/userCenters";
import Vue from "vue";
import * as types from "@/store/action-types";
import { Notification, MessageBox, Message } from "element-ui";
export default {
  data() {
    return {
      total: 0,
      searchType: 1,
      list: [],
      page: {
        pageNum: 1,
        pageSize: 10,
        searchType: "1",
        enterpriseId: "",
      },
      activeName: "first",
      value: "",
      input: "",
      option: [
        {
          value: "-1",
          label: "待确认",
        },
        {
          value: "0",
          label: "已发起",
        },
        {
          value: "2",
          label: "对方拒绝",
        },
        {
          value: "1",
          label: "已形成订单",
        },
        {
          value: "3",
          label: "管理员审核拒绝",
        },
      ],
      options: [
        {
          value: "1",
          label: "挂牌销售",
        },
        {
          value: "2",
          label: "挂牌采购",
        },
      ],
    };
  },
  components: {
    initiate: () => import("./components/initiate"),
    received: () => import("./components/received"),
    other: () => import("./components/other"),
  },
  created() {
    if (this.$route.query.type) {
      this.activeName = "third";
      this.page.searchType = "3";
    }
    let enterpriseId = Vue.ls.get(types.enterpriseId);
    this.page.enterpriseId = enterpriseId;
    this.getlist();
  },
  methods: {
    handleCurrentChange(e) {
      console.log(e);
      this.page.pageNum = e;
      this.getlist();
    },
    receivedChange(e) {
      if (e.code == 0) {
        this.getlist();
      }
      console.log(e);
    },
    optionsChange(e) {
      if (e) {
        this.page.tradeType = "1";
      } else {
        this.page.tradeType = "";
      }
    },
    searchFor() {
      this.page.pageNum = 1;
      this.getlist();
    },
    resetting() {
      this.page = {
        pageNum: 1,
        pageSize: 10,
        searchType: this.searchType.toString(),
        enterpriseId: Vue.ls.get(types.enterpriseId),
      };
      this.getlist();
    },
    getlist() {
      let config = {
        ...this.page,
      };
      listTradeInvite(config).then((res) => {
        if (res.code == 0) {
          this.list = res.data.list;
          this.list.forEach((item) => {
            let goodsName = "";
            let goodsPrice = "";
            item.goodsCategoryList.forEach((good, index) => {
              goodsName +=
                good.goodsName +
                (index == item.goodsCategoryList.length - 1 ? "" : ",");
              goodsPrice +=
                "￥" +
                good.goodsPrice +
                (index == item.goodsCategoryList.length - 1 ? "" : ",");
            });
            item.goodsName = goodsName;
            item.listingPrice = goodsPrice;
          });
          console.log(this.list);
          this.total = res.data.total;
        }
      });
    },
    addinvitation() {
      const msg = window.localStorage.getItem("massage");
      if (msg == "未登录请前往登录") {
        this.$message({
          message: msg,
          type: "error",
        });
        return;
      } else if (msg == "您的企业信息正在审核中请耐心等待") {
        MessageBox.alert(msg, "", {
          confirmButtonText: "已知悉",
        });
      } else if (msg == "请前往认证后操作") {
        MessageBox.confirm(msg, "提示", {
          confirmButtonText: "交易会员认证",
          cancelButtonText: "中长期会员认证",
          type: "warning",
          customClass: "hy-confirm",
          iconClass: "el-icon-info",
        })
          .then(() => {
            this.$router.push("/authentication");
          })
          .catch(() => {
            this.$router.push("/MediumTerm");
          });
      } else {
        this.$router.push({
          path: "/addInvitation",
        });
      }
      // let enterpriseId = Vue.ls.get(types.enterpriseId);
      // if (!enterpriseId) {
      //   this.$message({
      //     message: "请先登录",
      //     type: "warning",
      //   });
      // } else {
      //   getCompanyAuthDetail().then((res) => {
      //     if (res.data.auditStatus == 1) {
      //       this.$router.push({
      //         path: "/addInvitation",
      //       });
      //     }
      //   });
      // }
    },
    handleClick(tab, event) {
      console.log(tab.index);
      this.page.pageNum = 1;
      this.searchType = Number(tab.index) + 1;
      this.page.searchType = Number(tab.index) + 1;
      if (this.searchType != 1) {
        this.option = [
          {
            value: "0",
            label: "未处理",
          },
          {
            value: "2",
            label: "已拒绝",
          },
          {
            value: "1",
            label: "已形成订单",
          },
        ];
      } else {
        this.option = [
          {
            value: "-1",
            label: "待确认",
          },
          {
            value: "0",
            label: "已发起",
          },
          {
            value: "2",
            label: "对方拒绝",
          },
          {
            value: "1",
            label: "已形成订单",
          },
          {
            value: "3",
            label: "管理员审核拒绝",
          },
        ];
      }
      this.getlist();
    },
  },
};
</script>

<style lang="scss" scoped>
.Invitation {
  margin: 20px auto;

  .inform-main {
    width: 1200px;
    margin: 0 auto 100px;

    .I_item {
      display: flex;
      border: 1px solid #ccc;
      border-radius: 10px;
      padding: 10px;
      background: #fff;
    }
  }
}

::v-deep .el-tabs__active-bar {
  width: 33% !important;
}

::v-deep .el-tabs__nav {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

::v-deep .el-tabs__item {
  width: 50%;
  text-align: center;
}

.img_T {
  min-width: 1903px;
  img {
    width: 100%;
    height: auto;
  }
}
</style>
